import amplitude from 'amplitude-js';

amplitude.getInstance().init(window.pet_config.amplitude_key);

// Save to window to use it in scripts tags
window.amplitudeInstance = amplitude.getInstance();

const identifyEmail = () => {
  const queryParams = new URLSearchParams(window.location.search);

  if (queryParams.get('source') === 'mail') {
    amplitude.identify(new amplitude.Identify().set('mail', 'yes'));

    if (queryParams.has('mail_campaign')) {
      amplitude.identify(new amplitude.Identify().set('mail_campaign', queryParams.get('mail_campaign')));
    }
    if (queryParams.has('utm_campaign')) {
      amplitude.identify(new amplitude.Identify().set('campaign', queryParams.get('utm_campaign')));
    }
    if (queryParams.has('utm_content')) {
      amplitude.identify(new amplitude.Identify().set('adset', queryParams.get('utm_content')));
    }
    if (queryParams.has('ad.id')) {
      amplitude.identify(new amplitude.Identify().set('ad', queryParams.get('ad.id')));
    }
    if (queryParams.has('campaign_name')) {
      amplitude.identify(new amplitude.Identify().set('campaign_name', queryParams.get('campaign_name')));
    }
    if (queryParams.has('adset_name')) {
      amplitude.identify(new amplitude.Identify().set('adset_name', queryParams.get('adset_name')));
    }
    if (queryParams.has('ad_name')) {
      amplitude.identify(new amplitude.Identify().set('ad_name', queryParams.get('ad_name')));
    }
  }
};

export default amplitude.getInstance();
export { identifyEmail };
