import * as Sentry from '@sentry/browser';

function logImgLoadError() {
    const AllURLImages = document.querySelectorAll("img");

    AllURLImages.forEach((img) => {
        img.addEventListener("load", () => {
            Sentry.metrics.increment("img_load_status", 1, {
                tags: { img_src: img.src, status: 'success' }
            });
        });
        img.addEventListener("error", () => {
            Sentry.metrics.increment("img_load_status", 1, {
                tags: { img_src: img.src, status: 'error' }
            });
        });
    });
}

export {logImgLoadError};
