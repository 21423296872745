const localStorageKey = 'visit_id';

const getVisitIdFromLocalStorage = () => window.localStorage.getItem(localStorageKey);
const saveVisitIdToLocalStorage = (visitId) => window.localStorage.setItem(localStorageKey, visitId);

const pushVisitIdToQuery = (visitId) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.append('visit_id', visitId);

    if (window.history.pushState) {
        const newURL = new URL(window.location.href);
        newURL.search = queryParams.toString();
        window.history.replaceState({ path: newURL.href }, '', newURL.href);
    }
};

const checkVisitIdInQuery = async () => {
    if (window.isBot) {
        return;
    }

    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.has('visit_id')) {
        return;
    }

    let visitId = getVisitIdFromLocalStorage();

    if (visitId === null) {
        visitId = await getVisitIdFromBackend();
        saveVisitIdToLocalStorage(visitId);
    }

    pushVisitIdToQuery(visitId);
};

/**
 * @returns {Promise<string>}
 */
const getVisitIdFromBackend = () => {
    const url = new URL(window.location.origin + '/api/v1/user/get-visit-id');

    return new Promise((resolve, reject) => {
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data === null) {
                    throw new Error('visit_id is null');
                }

                if (data.error !== undefined) {
                    throw new Error(data.error);
                }

                resolve(data.visit_id);
            })
            .catch((err) => reject(err));
    });
};

export { checkVisitIdInQuery };
