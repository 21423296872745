import amplitude from './amplitude';

function initHamburger() {
    const hamburgerActive = document.querySelectorAll('.hamburger-close');
    const hamburgerBtn = document.querySelector('.hamburger__btn');
    const overlayId = document.getElementById('overlay');

    hamburgerActive.forEach((activeBtn) => {
        activeBtn.addEventListener('click', () => {
            overlayId.classList.toggle('open');
            hamburgerBtn.classList.toggle('open');
            document.body.classList.toggle('overflow');
            sendEventOfferViewOnce();
        });
    });

    /**
     * @type {NodeListOf<HTMLAnchorElement>}
     */
    const menuItems = document.querySelectorAll(".hamburger__overlay-list_item-link");
    const currentPath = window.location.pathname;

    menuItems.forEach(item => {
        const itemURL = new URL(item.href);

        if (itemURL.pathname === currentPath) {
            item.classList.add("active");
        }
    });

    // Send amplitude log event when offer is in viewport
    let sendEventOfferViewOnce = (() => {
        let called = false;

        return () => {
            if (!called) {
                amplitude.logEvent('Burger');
                called = true;
            }
        };
    });
}

initHamburger();
