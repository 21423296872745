import * as Sentry from "@sentry/browser";

function executeSafely(fn, ...args) {
    try {
        return fn(...args);
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
    }
}

export { executeSafely };
